<script setup lang="ts">
import type { ResponsiveImageType } from "vue-datocms";
import type { FormSectionRecord } from "~~/server/graphql/datocms/generated";
import { Image as DatocmsImage } from "vue-datocms";

const props = defineProps<{ data: FormSectionRecord }>();

const hasImage = computed(() => {
  return !!props.data?.optImage;
});
</script>

<template>
  <div
    class="bg-dairy relative mx-auto grid max-w-md grid-cols-1 rounded-lg"
    :class="{ 'max-w-none lg:grid-cols-2 ': hasImage }"
  >
    <div id="section-form" class="px-lg py-3xl lg:px-3xl">
      <h3 class="mb-md">
        {{ data.title }}
      </h3>
      <div v-if="data.optIntro" class="mb-lg lg:mb-xl" v-html="data.optIntro" />
      <FormTallyEmbed :tally-form-id="data.tallyFormId" />
    </div>

    <DatocmsImage v-if="data.optImage" :data="data.optImage.responsiveImage as ResponsiveImageType" object-fit="cover" class="rounded-lg" />

    <VectorWithStroke class="vector--main-image absolute -bottom-4 right-5 h-[1.95rem] w-8 xl:h-[4.5rem] xl:w-[4.625rem]" />
  </div>
</template>

<style scoped>
.vector--main-image {
  --esc-vector-fill-color: #73b7b6;
  --esc-vector-opacity: 1;
  --esc-vector-mix-blend-mode: normal;
}
</style>
