<script lang="ts" setup>
const props = defineProps<{ tallyFormId: string }>();

const { t } = useI18n();

declare const Tally: {
  loadEmbeds: () => void;
} | undefined;

useHead({
  script: [
    { src: "https://tally.so/widgets/embed.js", async: true, defer: true },
  ],
});

// This is needed for SSR only
// onMounted + nextTick required, see https://nuxt.com/docs/guide/directory-structure/components#client-components
// typeof check required, see https://medium.com/nona-web/the-curious-case-of-nuxt-error-reference-error-geoip2-is-not-defined-b808284b024b
onMounted(async () => {
  await nextTick();
  if (typeof Tally !== "undefined") {
    // eslint-disable-next-line no-console
    console.debug("🦩 onMounted: Loading Tally Embeds");
    Tally.loadEmbeds();
  }
});

const tallySrc = computed(() => {
  return `https://tally.so/embed/${props?.tallyFormId}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`;
});
</script>

<template>
  <div class="my-5">
    <ClientOnly>
      <iframe
        :data-tally-src="tallySrc"
        loading="lazy" width="100%" height="500" frameborder="0" marginheight="0" marginwidth="0"
        title="Form"
        class="-m-xs"
      />
      <template #fallback>
        <div class="h-[480px] ps-[8px] pt-4">
          <p>
            <UIcon name="i-ph-circle-notch-bold" class="size-8 animate-spin" />
          </p>
          <p class="text-sm">
            {{ t('form.loading') }}
          </p>
        </div>
      </template>
    </ClientOnly>
  </div>
</template>

<style scoped>

</style>
